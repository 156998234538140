export const comments = {
  ja: {
    score: {
      up: `生活習慣スコアは2月より%d点上がりました。年度末の慌ただしい時期に素晴らしいです！<br>
      暖かくなり活動的になる春に、10分間身体を動かしませんか？<br>
      4月4日からモーニングストレッチが「<b>Team 10 minutes</b>」としてリニューアル！<br>
      ピラティスやヨガなど曜日ごとに5つのテーマを設定。<a href="https://go.andwell.jp/events">参加する</a>をご覧ください。`,
      down: `生活習慣スコアは2月より%d点下がりました。分類別評価でどの項目に変化があったのか見てみましょう。<br>
      暖かくなり活動的になる春に、10分間身体を動かしませんか？<br>
      4月4日からモーニングストレッチが「<b>Team 10 minutes</b>」としてリニューアル！<br>
      ピラティスやヨガなど曜日ごとに5つのテーマを設定。<a href="https://go.andwell.jp/events">参加する</a>をご覧ください。
      `,
      equal: `生活習慣スコアは2月と同じ点数です。分類別評価で、項目別に振り返ってみましょう。<br>
      暖かくなり活動的になる春に、10分間身体を動かしませんか？<br>
      4月4日からモーニングストレッチが「<b>Team 10 minutes</b>」としてリニューアル！<br>
      ピラティスやヨガなど曜日ごとに5つのテーマを設定。<a href="https://go.andwell.jp/events">参加する</a>をご覧ください。
      `,
      equal100: `生活習慣スコアは2月に引き続き<span class="good">満点</span>です。年度末の慌ただしい時期に大変素晴らしいです！<br>
      暖かくなり活動的になる春に、10分間身体を動かしませんか？<br>
      4月4日からモーニングストレッチが「<b>Team 10 minutes</b>」としてリニューアル！<br>
      ピラティスやヨガなど曜日ごとに5つのテーマを設定。<a href="https://go.andwell.jp/events">参加する</a>をご覧ください。
      `,
    },
    steps: {
      up: `歩数スコアは2月より%d点上がりました。<br>
      4月15日より<b>Walk チーム対抗戦 2022 1st</b>のエントリーが始まります。<br>
      新年度のはじまりに、新しい仲間と交流するきっかけにも！`,
      down: `歩数スコアは2月より%d点下がりました。<br>
      4月15日より<b>Walk チーム対抗戦 2022 1st</b>のエントリーが始まります。<br>
      新年度のはじまりに、新しい仲間と交流するきっかけにも！`,
      equal: `歩数スコアは2月と同じです。<br>
      4月15日より<b>Walk チーム対抗戦 2022 1st</b>のエントリーが始まります。<br>
      新年度のはじまりに、新しい仲間と交流するきっかけにも！`,
    },
    exercise: {
      up: `運動のスコアは2月より%d点上がりました。<br>
      階段を積極的に利用したり、早歩きしたりなど、ちょっとした工夫や心がけで日常生活の中で活動量を増やすことができますよ。`,
      down: `運動のスコアは2月より%d点下がりました。<br>
      階段を積極的に利用したり、早歩きしたりなど、ちょっとした工夫や心がけで日常生活の中で活動量を増やすことができますよ。`,
      equal: `運動のスコアは2月と同じです。<br>
      階段を積極的に利用したり、早歩きしたりなど、ちょっとした工夫や心がけで日常生活の中で活動量を増やすことができますよ。`,
    },
    meal: {
      up: `食事のスコアは2月より%d点上がりました。<br>
      新生活などさまざまなことが始まる春。<br>好スタートのカギは“〇〇”！<br>
      この<a href="https://go.andwell.jp/library/4a19a36d-5e33-11ec-9728-06d158a16ffc">健康コラム</a>を読んで、快適に過ごしましょう。`,
      down: `食事のスコアは2月より%d点下がりました。<br>
      新生活などさまざまなことが始まる春。<br>好スタートのカギは“〇〇”！<br>
      この<a href="https://go.andwell.jp/library/4a19a36d-5e33-11ec-9728-06d158a16ffc">健康コラム</a>を読んで、快適に過ごしましょう。`,
      equal: `食事のスコアは2月と同じです。<br>
      新生活などさまざまなことが始まる春。<br>好スタートのカギは“〇〇”！<br>
      この<a href="https://go.andwell.jp/library/4a19a36d-5e33-11ec-9728-06d158a16ffc">健康コラム</a>を読んで、快適に過ごしましょう。`,
    },
    drinking: {
      up: `飲酒のスコアは2月より%d点上がりました。<br>
      アルコールには利尿作用があり、お酒を飲むと脱水症状になりやすいです。<br>
      こまめに水を飲むことを意識しましょう。`,
      down: `飲酒のスコアは2月より%d点下がりました。<br>
      アルコールには利尿作用があり、お酒を飲むと脱水症状になりやすいです。<br>
      こまめに水を飲むことを意識しましょう。`,
      equal: `飲酒のスコアは2月と同じです。<br>
      アルコールには利尿作用があり、お酒を飲むと脱水症状になりやすいです。<br>
      こまめに水を飲むことを意識しましょう。`,
    },
    sleep: {
      up: `睡眠のスコアは2月より%d点上がりました。<br>
      春は1年の中で特に寒暖差が激しく、睡眠の質が下がりやすい時期です。<br>
      朝起きたらすぐに太陽の光を浴びて、体内時計をリセット！`,
      down: `睡眠のスコアは2月より%d点下がりました。<br>
      春は1年の中で特に寒暖差が激しく、睡眠の質が下がりやすい時期です。<br>
      朝起きたらすぐに太陽の光を浴びて、体内時計をリセット！`,
      equal: `睡眠のスコアは2月と同じです。<br>
      春は1年の中で特に寒暖差が激しく、睡眠の質が下がりやすい時期です。<br>
      朝起きたらすぐに太陽の光を浴びて、体内時計をリセット！`,
    },
    stress: {
      up: `ストレスのスコアは2月より%d点上がりました。<br>
      新年度は異動など、環境の変化が多くありますね。<br>
      初対面の方と上手く話せない…<br>とお悩みの方にピッタリの動画が<a href="https://go.andwell.jp/library/9693bead-98ff-11ec-b342-06d158a16ffc">こちら</a>！`,
      down: `ストレスのスコアは2月より%d点下がりました。<br>
      新年度は異動など、環境の変化が多くありますね。<br>
      初対面の方と上手く話せない…<br>とお悩みの方にピッタリの動画が<a href="https://go.andwell.jp/library/9693bead-98ff-11ec-b342-06d158a16ffc">こちら</a>！`,
      equal: `ストレスのスコアは2月と同じです。<br>
      新年度は異動など、環境の変化が多くありますね。<br>
      初対面の方と上手く話せない…<br>とお悩みの方にピッタリの動画が<a href="https://go.andwell.jp/library/9693bead-98ff-11ec-b342-06d158a16ffc">こちら</a>！`,
    },
  },
  en: {
    score: {
      up: `Your lifestyle score has gone up %d pts since February. You're doing great during the hectic fiscal year's end!<br>
      Can you set aside 10 minutes to get active as we move into the warmer, more active spring?<br>
      If so, the Morning Stretches are coming back as <b>Team 10 Minutes</b> from Apr. 4!<br>
      Five themes for each day of the week, including Pilates and yoga. Please see <a href="https://go.andwell.jp/events">Event Page</a>.`,
      down: `Your lifestyle score has gone down %d pts since February. Use the categorized ratings to see what items have changed. <br>
      Can you set aside 10 minutes to get active as we move into the warmer, more active spring?<br>
      If so, the Morning Stretches are coming back as <b>Team 10 Minutes</b> from Apr. 4!<br>
      Five themes for each day of the week, including Pilates and yoga. Please see <a href="https://go.andwell.jp/events">Event Page</a>.`,
      equal: `Your lifestyle score is the same as February. Use the categorized ratings to review your progress item by item.<br>
      Can you set aside 10 minutes to get active as we move into the warmer, more active spring?<br>
      If so, the Morning Stretches are coming back as <b>Team 10 Minutes</b> from Apr. 4!<br>
      Five themes for each day of the week, including Pilates and yoga. Please see <a href="https://go.andwell.jp/events">Event Page</a>.`,
      equal100: `Your lifestyle score is the <span class="good">maximum</span> again, the same as February. You're doing fantastic during the hectic fiscal year's end! <br>
      Can you set aside 10 minutes to get active as we move into the warmer, more active spring?<br>
      If so, the Morning Stretches are coming back as <b>Team 10 Minutes</b> from Apr. 4!<br>
      Five themes for each day of the week, including Pilates and yoga. Please see <a href="https://go.andwell.jp/events">Event Page</a>.`,
    },
    steps: {
      up: `Your steps score has gone up %d pts since February.<br>
      The entry period for the <b>1st Walk Team Competition 2022</b> will start on Apr. 15. <br>
      A new fiscal year is also a great time to start interacting with new friends!`,
      down: `Your steps score has gone down %d pts since February.<br>
      The entry period for the <b>1st Walk Team Competition 2022</b> will start on Apr. 15. <br>
      A new fiscal year is also a great time to start interacting with new friends!`,
      equal: `Your steps score is the same as February.<br>
      The entry period for the <b>1st Walk Team Competition 2022</b> will start on Apr. 15. <br>
      A new fiscal year is also a great time to start interacting with new friends!`,
    },
    exercise: {
      up: `Your exercise score has gone up %d pts since February.<br>
      You can increase your activity level in your daily life with just a bit of ingenuity and effort, like actively taking the stairs or walking fast.`,
      down: `Your exercise score has gone down %d pts since February.<br>
      You can increase your activity level in your daily life with just a bit of ingenuity and effort, like actively taking the stairs or walking fast.`,
      equal: `Your exercise score is the same as February.<br>
      You can increase your activity level in your daily life with just a bit of ingenuity and effort, like actively taking the stairs or walking fast.`,
    },
    meal: {
      up: `Your food score has gone up %d pts since February.<br>
      Many new things start in spring, including new lifestyles. The key to getting off to a good start is ***!<br>
      Try reading this <a href="https://go.andwell.jp/library/4a19a36d-5e33-11ec-9728-06d158a16ffc">Health Column</a> for a smooth start.`,
      down: `Your food score has gone down %d pts since February.<br>
      Many new things start in spring, including new lifestyles. The key to getting off to a good start is ***!<br>
      Try reading this <a href="https://go.andwell.jp/library/4a19a36d-5e33-11ec-9728-06d158a16ffc">Health Column</a> for a smooth start.`,
      equal: `Your food score is the same as February.<br>
      Many new things start in spring, including new lifestyles. The key to getting off to a good start is ***!<br>
      Try reading this <a href="https://go.andwell.jp/library/4a19a36d-5e33-11ec-9728-06d158a16ffc">Health Column</a> for a smooth start.`,
    },
    drinking: {
      up: `Your alcohol score has gone up %d pts since February.<br>
      Alcohol has a diuretic affect, so drinking it leaves you prone to dehydration.<br>
      Make sure you drink water frequently.`,
      down: `Your alcohol score has gone down %d pts since February.<br>
      Alcohol has a diuretic affect, so drinking it leaves you prone to dehydration.<br>
      Make sure you drink water frequently.`,
      equal: `Your alcohol score is the same as February.<br>
      Alcohol has a diuretic affect, so drinking it leaves you prone to dehydration.<br>
      Make sure you drink water frequently.`,
    },
    sleep: {
      up: `Your sleep score has gone up %d pts since February.<br>
      Temperatures vary heavily in spring, so your sleep quality can easily deteriorate in this part of the year.<br>
      As soon as you wake up in the morning, get into the sunlight and reset your internal clock!`,
      down: `Your sleep score has gone down %d pts since February.<br>
      Temperatures vary heavily in spring, so your sleep quality can easily deteriorate in this part of the year.<br>
      As soon as you wake up in the morning, get into the sunlight and reset your internal clock!`,
      equal: `Your sleep score is the same as February.<br>
      Temperatures vary heavily in spring, so your sleep quality can easily deteriorate in this part of the year.<br>
      As soon as you wake up in the morning, get into the sunlight and reset your internal clock!`,
    },
    stress: {
      up: `Your stress score has gone up %d pts since February.<br>
      A new fiscal year brings about a lot of environmental changes from relocations, etc.<br>
      <a href="https://go.andwell.jp/library/9693bead-98ff-11ec-b342-06d158a16ffc">This video</a> is perfect for those who have trouble talking to others when first meeting!`,
      down: `Your stress score has gone down %d pts since February.<br>
      A new fiscal year brings about a lot of environmental changes from relocations, etc.<br>
      <a href="https://go.andwell.jp/library/9693bead-98ff-11ec-b342-06d158a16ffc">This video</a> is perfect for those who have trouble talking to others when first meeting!`,
      equal: `Your stress score is the same as February.<br>
      A new fiscal year brings about a lot of environmental changes from relocations, etc.<br>
      <a href="https://go.andwell.jp/library/9693bead-98ff-11ec-b342-06d158a16ffc">This video</a> is perfect for those who have trouble talking to others when first meeting!`,
    },
  },
}
